var invalidProtocolRegex = /^([^\w]*)(javascript|data|vbscript)/im;
var ctrlCharactersRegex = /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
var urlSchemeRegex = /^([^:]+):/gm;
var relativeFirstCharacters = [".", "/"];
var extraValidation_2 = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=,]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=,]*)/g;
function isRelativeUrlWithoutProtocol(url) {
    return relativeFirstCharacters.indexOf(url[0]) > -1;
}
function sanitizeUrl(url) {
    if (!url) {
        return "about:blank";
    }
    var sanitizedUrl = url.replace(ctrlCharactersRegex, "").trim();

    invalidUrl = sanitizedUrl.replace(extraValidation_2, "");
    console.log("invalidUrl= "+ invalidUrl) // we will get extra invalid url only not entire url

    if(invalidUrl) {
    url_split = sanitizedUrl.split(invalidUrl);
    sanitizedUrl = url_split[0];
    }

    if (isRelativeUrlWithoutProtocol(sanitizedUrl)) {
        return sanitizedUrl;
    }
    var urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);
    if (!urlSchemeParseResults) {
        return sanitizedUrl;
    }
    var urlScheme = urlSchemeParseResults[0];
    if (invalidProtocolRegex.test(urlScheme)) {
        return "about:blank";
    }
    return sanitizedUrl;
}

if(window.location.pathname.includes('/products_search') ){
  var newurl = sanitizeUrl(window.location.href);
  if (newurl != window.location){
    location.href = newurl;
  }
}